.partner {
  --toggler-size: 40px;
  padding: 21px 20px 0;
  margin-top: 15px;
  width: 100%;
  box-sizing: border-box;
}
.partner__heading {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: calc(var(--toggler-size) + 8px);
  box-sizing: border-box;
  cursor: pointer;
}
.partner__summary-control {
  cursor: pointer;
  width: var(--toggler-size);
  height: var(--toggler-size);
  background-image: url('../images/partner--open.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: opacity 0.3s ease;
}
.partner__summary-control:hover {
  opacity: 0.65;
}
.partner__summary-control_type_mobile {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -21px;
}
.partner__summary-control_type_desktop {
  display: none;
}
.partner__content {
  display: none;
}
.partner__tags {
  display: none;
  gap: 12px;
}
.partner__tag {
  display: inline-block;
  font-family: 'TT Hoves Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  padding: 10px 30px;
  background: transparent;
  border: 2px solid #f0e3cb;
  border-radius: 70px;
  margin: 0;
  box-sizing: border-box;
}
.partner__tag_style_link {
  border-style: dashed;
}
.partner__tag_style_link::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url('../images/partner--link.svg');
  background-repeat: no-repeat;
  background-size: 12px;
}
.partner__tag_style_link a {
  text-decoration: none;
  color: #000;
  transition: opacity 0.3s ease;
}
.partner__tag_style_link a:hover {
  opacity: 0.7;
}
.partner__tag_style_1 {
  background: #000;
  color: #fffbf4;
  border-color: #000;
}
.partner__tag_style_2 {
  background: #f6ead4;
  border-color: transparent;
  border-radius: 0;
}
.partner__tag_style_3 {
  background: #f6ead4;
  border-color: transparent;
  border-radius: 12px;
}
.partner__tag_type_summary {
}
.partner__tag_type_content {
}
.partner__blockquote {
}

.partner a {
  color: inherit;
  transition: opacity 0.3s ease;
}
.partner a:hover {
  opacity: 0.7;
}

@media (max-width: 350px) {
  .partner {
    --toggler-size: 30px;
  }
  .partner__summary-control_type_mobile {
    top: 5px;
    margin-top: 0;
  }
}

@media (min-width: 1240px) {
  .partner {
    --toggler-size: 44px;
    max-width: 1348px;
    margin: 0 auto 16px;
    padding: 16px 34px;
  }
  .partner__summary {
    display: grid;
    grid-template-columns: 540px 1fr 44px;
  }
  .partner__summary-control_type_desktop {
    display: block;
    margin-top: 8px;
  }
  .partner__summary-control_type_mobile {
    display: none;
  }
  .partner__tags {
    display: flex;
    flex-wrap: wrap;
    align-self: baseline;
    padding-top: 8px;
  }
  .partner__tag_type_content {
    display: none;
  }

  .partner:hover {
    background-color: #fcf4e5;
    border-radius: 40px;
  }
}

.partner_open {
  background-color: #fcf4e5;
  border-radius: 32px;
  padding-bottom: 32px;
  margin-bottom: 31px;
}
.partner_open .partner__content {
  display: block;
}
.partner_open .partner__summary-control {
  background-image: url('../images/partner--close.svg');
  background-color: transparent;
  user-select: none;
}
.partner_open .partner__summary-control:hover {
  opacity: 0.8;
}
.partner_open .partner__tags {
  display: flex;
  flex-wrap: wrap;
  margin: 32px 0;
}
.partner_open .partner__blockquote {
  margin-left: -20px;
  margin-right: -20px;
}

@media (min-width: 650px) {
  .partner_open .partner__blockquote {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1240px) {
  .partner_open .partner__about {
    padding-left: 540px;
  }
  .partner_open .partner__tags {
    margin: 0 0 44px;
  }
  .partner_open .partner__tag_type_content {
    display: inline-block;
  }
}

.menu {
  width: 60px;
  height: 50px;
  position: fixed;
  top: 16px;
  left: 7px;
  z-index: 10000;
}
.menu__control {
  display: block;
  cursor: pointer;
  background-color: #fffbf4;
  border: none;
  width: 60px;
  height: 50px;
  border-radius: 22px;
  background-image: url('../images/menu--burger.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 34px;
  transition: opacity 0.3s ease;
}
.menu__control:hover {
  opacity: 0.7;
}
.menu__content {
  display: none;
}
.menu__item {
  padding-bottom: 28px;
}
.menu__link {
  text-decoration: none;
  color: #000;
}
.menu__link:hover {
  color: #444;
}

@media (min-width: 1100px) {
  .menu {
    top: 23px;
    left: 72px;
    position: absolute;
  }
  .menu__control {
    background-size: 44px;
  }
}

.menu_open {
  padding: 0;
  background-color: #9eccc9;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.menu_open .menu__control {
  background-color: transparent;
  background-image: url('../images/menu--close.svg');
  width: 74px;
  height: 82px;
}
.menu_open .menu__content {
  display: block;
  font-family: 'Spoof Trial Medium', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
  padding: 20px;
}

@media (min-width: 1100px) {
  .menu_open {
    position: fixed;
    width: 400px;
  }
}

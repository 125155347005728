.blockquote {
}
.blockquote a {
  color: inherit;
  transition: opacity 0.3s ease;
}
.blockquote a:hover {
  opacity: 0.7;
}
.blockquote__text {
}
.blockquote__footer {
}

.blockquote_style_1 {
}
.blockquote_style_1 .blockquote__text {
  font-family: 'Spoof Trial Black', sans-serif;
  font-size: 35px;
  position: relative;
  line-height: 138%;
  letter-spacing: 0.03em;
  padding-top: 78px;
}
.blockquote_style_1 .blockquote__text::before {
  content: '“';
  display: block;
  font-size: 123px;
  line-height: 56px;
  position: absolute;
  top: 30px;
  left: 0;
}
@media (min-width: 1100px) {
  .blockquote_style_1 .blockquote__text {
    padding: 34px 20px 32px 125px;
    line-height: 52px;
  }
  .blockquote_style_1 .blockquote__text::before {
    top: 60px;
  }
}

.blockquote_style_2 {
  background: #f6ead4;
  padding: 20px 20px 28px;
  border-radius: 32px;
  margin-bottom: 32px;
}
.blockquote_style_2 .blockquote__text {
  font-family: 'Spoof Trial Medium', sans-serif;
  font-size: 20px;
  line-height: 29px;
  padding-bottom: 16px;
}
.blockquote_style_2 .blockquote__footer a {
  color: inherit;
}
.blockquote_style_2 .blockquote__footer a:hover {
  opacity: 0.65;
}
@media (min-width: 650px) {
  .blockquote_style_2 .blockquote__text {
    font-size: 24px;
  }
}

.blockquote_style_3 {
  padding-bottom: 12px;
}
.blockquote_style_3 .blockquote__text {
  font-family: 'Spoof Trial Medium', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  padding-top: 50px;
  padding-bottom: 20px;
  position: relative;
}
.blockquote_style_3 .blockquote__text::before {
  content: '“';
  display: block;
  font-size: 150px;
  line-height: 50px;
  position: absolute;
  top: 18px;
  left: -8px;
}
@media (min-width: 650px) {
  .blockquote_style_3 .blockquote__text {
    font-size: 24px;
    line-height: 36px;
  }
}
@media (min-width: 1100px) {
  .blockquote_style_3 {
    position: relative;
    padding-left: 420px;
    padding-bottom: 60px;
    padding-right: 80px;
  }
  .blockquote_style_3::before {
    content: '';
    display: block;
    width: 320px;
    height: 250px;
    background-image: url('../images/quotes/quote-1.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 80px;
    top: 0;
  }
  .blockquote_cover_2::before {
    background-image: url('../images/quotes/quote-2.svg');
  }
  .blockquote_cover_3::before {
    background-image: url('../images/quotes/quote-3.svg');
  }
  .blockquote_cover_4::before {
    background-image: url('../images/quotes/quote-4.svg');
  }
  .blockquote_cover_5::before {
    background-image: url('../images/quotes/quote-5.svg');
  }
}

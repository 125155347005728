@import 'styles/reset.css';
@import 'styles/blockquote.css';
@import 'styles/effect.css';
@import 'styles/flexy.css';
@import 'styles/heading.css';
@import 'styles/hero.css';
@import 'styles/menu.css';
@import 'styles/muffins-earth.css';
@import 'styles/number.css';
@import 'styles/numbers.css';
@import 'styles/outstander.css';
@import 'styles/page.css';
@import 'styles/partner.css';
@import 'styles/path.css';
@import 'styles/section.css';
@import 'styles/slider.css';
@import 'styles/stats.css';
@import 'styles/text.css';
@import 'styles/things.css';
@import 'styles/why-us-list.css';
@import 'styles/word.css';

@font-face {
  font-family: 'Spoof Trial Black';
  src: url('fonts/spoof-trial/SpoofTrial-Black.otf') format('opentype');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'Spoof Trial Medium';
  src: url('fonts/spoof-trial/SpoofTrial-Medium.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'TT Hoves Bold';
  src: url('fonts/tt-hoves/TT Hoves Bold.otf') format('opentype');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'TT Hoves Regular';
  src: url('fonts/tt-hoves/TT Hoves Regular.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}

:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #fffbf4;
  box-sizing: border-box;
  position: relative;
}
html::after,
body::after {
  content: '';
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fffbf4;
}
html::after {
  top: -100vh;
}
body::after {
  bottom: -100vh;
}

img {
  max-width: 100%;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
}

.muffins {
  font-family: 'Spoof Trial Black', sans-serif;
  display: block;
}
@media (min-width: 350px) and (max-width: 449px) {
  .muffins {
    font-size: 20vw;
    font-weight: 800;
    line-height: 71px;
    letter-spacing: 1px;
    text-align: center;
  }
}
@media (min-width: 450px) {
  .muffins {
    display: inline;
  }
}

.logos {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  flex-wrap: wrap;
  padding: 0 10px;
}
.logos__item {
  height: 60px;
  padding-bottom: 4px;
}
@media (min-width: 1100px) {
  .logos {
    gap: 72px;
  }
}

.cite {
  display: flex;
  gap: 12px;
  text-align: left;
}
.cite__image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-top: 12px;
}
@media (min-width: 400px) {
  .cite__image {
    margin-top: 0;
  }
}

.email {
  font-family: 'Spoof Trial Medium', sans-serif;
  font-size: 32px;
  font-weight: 96;
  line-height: 1;
  letter-spacing: 0;
  text-align: left;
  color: #000;
  text-decoration: none;
  position: relative;
  width: fit-content;
  transition: opacity 0.3s ease;
}
.email:hover {
  opacity: 0.7;
}
.email::after {
  content: '';
  display: block;
  width: 100%;
  height: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #f9cb67;
  z-index: -1;
}
@media (min-width: 1100px) {
  .email {
    font-size: 40px;
  }
  .email::after {
    height: 14px;
    bottom: 1px;
  }
}

.contacts {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.contacts__item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
@media (min-width: 650px) {
  .contacts {
    padding-top: 4px;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 20px;
  }
  .contacts__item {
    text-align: center;
    gap: 16px;
  }
}

.nutella {
  font-family: 'Spoof Trial Black', sans-serif;
  font-size: 36px;
  font-weight: 200;
  line-height: 48px;
  text-align: left;
}
.nutella__title {
  padding-bottom: 32px;
}
.nutella__highlight {
  color: #f97362;
}
@media (min-width: 1100px) {
  .nutella {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 20px;
  }
}
